.radio-btn {
  border: 1px solid;
  padding: 5px;
  display: inline-block;
  cursor: pointer;
  background: $darkInput;
  color: #fff;
  border: 1px solid transparent;

  & + .radio-btn {
    border-left-color: rgba(250, 250, 250,.15);
  }

  &:hover {
    border: 1px solid rgba(250, 250, 250,.3);
  }


  &__selected {
    border-color: $primaryColor !important;
    color: #fff;
  }



  &__number {
    margin-left: 10px;
    border-radius: 30px;
    padding: 3px 5px;
  }
}