.btn {
  @include commonBtnStyles;
  @include btn-primary;

  &--border {
    @include btn-border;
  }
  &--second {
    @include btn-secondary;
  }
  &--black {
    background: #000;
    color: #fff;

    &:hover {
      color: #fff;
      background-color: lighten(#000, 20%);
    }
  }
}

.ant {
  &-btn {

    @include commonBtnStyles;
    @include btn-secondary;
    path {
      fill: #fff;
    }
    &-lg {
      height: 45px;
    }

    &-circle.ant-btn-lg {
      height: 45px;
      width: 45px;
    }

    &-round {
      display: inline-flex;
      border-radius: 30px;
      align-items: center;

      &.ant-btn-lg {
        border-radius: 30px;
        font-size: 12px;
        padding: 10px 40px;
        height: 45px;
      }
    }

    &-primary {
      @include btn-secondary;
    }

    &-secondary {
      @include btn-secondary;
    }

    &-tertiary {

      @include btn-tertiary;
    }

    &-default {
      @include btn-default;
    }

    &-with-icon {
      @include with-icon;
    }

    &-loading {
      @include loading;
    }
  }
}
