.landing-pages {
  .header {
    margin: 0;
    position: relative;
    background: $darkGradient;
    &__wrap {
      display: flex;
      align-items: center;
      padding: 20px 0;
      position: relative;
      z-index: 5;
    }

    .btn__mobile-burger {
      display: none;
    }

    &__navigation-wrap {
      display: flex;
      align-items: center;
      width: 100%;

      @include media("<=860px") {
        margin-right: auto;
      }

      &--modal {
        display: block;

        ul.nav-menu {
          margin: 0;
          padding: 0;
          flex-direction: column;

          li {
            margin: 0 auto 15px !important;
          }

          a {
            font-size: 18px;
            line-height: 1.5;
          }
        }

        .language-dropdown {
          margin: 0 auto;
          width: 40px;
          display: block;
          font-size: 14px;
          line-height: 1.3;
          &__flag {
            width: 20px;
            height: 20px;
          }
        }

        .header__button-wrap {
          position: absolute;
          left: 0;
          bottom: 50px;
          padding: 0 15px;
          margin: 0;
          width: 100%;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          .btn {
            width: 100%;
            margin: 0;

            +.btn {
              margin-top: 10px;
            }
          }
        }
      }
    }

    .nav-menu {
      display: flex;
      align-items: center;
      margin: 0 auto;
      list-style: none;

      &__item {
        &:not(:last-child) {
          margin: 0 30px 0 0;
        }
      }

      &__link {
        color: $white;
        font-size: 12px;
        line-height: 21px;

        &.active {
          color: $primaryColor;

        }

        &:hover {
          color: $primaryColor;
        }
      }
    }

    &__logo {

      &-link, &-cont {
        display: flex;
        align-items: center;
      }
    }

    &__button-wrap {
      display: flex;

      .btn {
        +.btn {
          margin-left: 10px;
          @include media("<568px") {
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }
    }

    &__mobile-menu {
      display: none;
      justify-content: space-between;
      align-items: center;
      width: auto;
      font-weight: 400;
      color: $white;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
    }

    @include maxWidth(860px) {
      .btn__mobile-burger {
        display: block;
        padding: 14px 6px;
      }
      &__mobile-menu {
        display: flex;
      }
      &__navigation-wrap {
        display: none;

        &--modal {
          display: block;
        }
      }
    }
  }
}
.header-logo {

  @include media('<1024px') {
    width: 150px;

    img {
      width: 100%;
    }
  }
}