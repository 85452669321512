.ant {
  &-select {
    &-multiple {
      .ant-select {
        &-selector {
          border-radius: 30px;
          min-height: 45px;
          &:has(span.ant-select-selection-item) {
            height: unset !important;
          }
        }
        &-selection {
          &-placeholder {
            text-align: left;
            padding-left: calc(6% - 7px);
          }
          &-item {
            background: #eaf7ef;
            color: #27ae60;
            border-radius: 30px;
            height: 37px;
            padding: 6px 4px 0 8px;
            margin-top: 3px;
            margin-bottom: 3px;
            &-remove {
              svg {
                font-size: 8px;
                color: #27ae60;
              }
            }
          }
        }
      }
    }
    &-single:not(.ant-select-customize-input) {
      .ant-select {
        &-selector {
          border-radius: 30px;
          min-height: 45px;
        }
        &-selection {
          &-item {
            background: #f2f7ff;
            color: #0066ff;
            margin-top: 8px;
            margin-bottom: 10px;
            margin-left: 15px;
            padding: 0 5px;
            border-radius: 30px;
            text-align: left;
            flex: unset;
          }
          &-placeholder {
            padding-top: 8px;
            text-align: left;
            padding-left: calc(6% - 11px);
          }
        }
      }
    }
  }

  &-input{
    padding-left: 11px;
    &-group {
      &-addon{
        &:last-child {
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
          background: transparent;
        }
      }
      & > .ant-input:first-child, .ant-input-group-addon:first-child {
        border-right: none;
      }

    }
  }
}
