@mixin input {
  border-radius: 30px;
  height: 45px;
  padding-left: 11px;
  box-sizing: border-box;
}

@mixin input-active {
  box-shadow: 0 0 0 2px $fieldsActiveBoxShadow; //0px 4px 10px $fieldsActiveBoxShadow;
  border-color: $fieldsActiveBorderColor;
}

.ant-form {
  &-item {
    margin-bottom: 14px;
    &-explain {
      padding-left: 11px;
    }

    &-label {
      & > label {
        height: 45px;
        color: $greyMain;
      }
    }
  }
  &__label {
    color: $greyMain;
    margin-bottom: 5px;
    display: block;
  }
}

.ant-input-affix-wrapper {
  max-height: initial;
  border-radius: 0px;
  padding: 0;
  & > input {
    &.ant-input {
      @include input;
    }
  }

  &-focused {
    @include input-active;
  }

  &:hover {
    @include input-active;
  }
}

.ant-input {
  @include input;

  &-focused {
    @include input-active;
  }

  &:hover,
  &:focus {
    @include input-active;
  }


}

textarea {
  &.ant-input {
    border-radius: 0px;
  }
}
