.commissions-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  >* {
    width: calc(50% - 10px);
    margin-bottom: 20px;

    @include media('<1024px') {
      width: 100%;
    }
  }
}
.commission-page-wrap {
  position: relative;

  @include media('<1024px') {
    padding: 0;
  }

  &__image {
    width: 600px;
    height: 652px;
    position: absolute;
    left: 10px;
    bottom: -40px;
    pointer-events: none;
    user-select: none;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @include media('<1024px') {
      display: none;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 900;
    margin-bottom: 20px;

    @include media('<1024px') {
      font-size: 24px;
    }
  }
}

.commission-card {
  border-radius: 30px;
  background: $darkBox;
  padding: 20px;
  font-family: monospace;

  @include media('<1024px') {
    padding: 15px;
  }
  &__title {
    font-size: 24px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 5px;
    max-width: 200px;
    min-height: 60px;

    @include media('<1024px') {
      font-size: 18px;
      min-height: initial;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 1.4;
    min-height: 50px;
    margin-bottom: 10px;

    @include media('<1024px') {
      font-size: 12px;
      min-height: initial;
    }
  }
  &__list {
    list-style: none;
    padding: 0;
    font-size: 16px;
    line-height: 1.5;

    @include media('<1024px') {
      font-size: 14px;
    }
    li {
      position: relative;
      padding-left: 10px;
      +li {
        margin-top: 15px;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 9px;
        width: 4px;
        height: 4px;
        background: #fff;
        border-radius: 50%;
      }
    }
  }
}