.auth-wrapper {
  @include flex($type: center, $direction: column);
  min-height: 100vh;
  padding: 60px 0;
  background: $dark;
  a {
    color: #fff;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: $primaryColor;
    }
  }
  &__content {
    width: 500px;
    max-width: 95%;
  }

  .ant-card-head-title {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 0;
  }

  .ant-card-head,
  .ant-checkbox-wrapper {
    color: $white;
  }
  .ant-card {
    background-color: $darkBox;
    color: $white;

    &.ant-card-bordered {
      border-color: $darkInput;
    }
  }
}

.auth-form {


  &__footer {
    @include flex($direction: column, $justify-content: center,);
    text-align: center;
  }

  .ant-btn {
    margin: 0 auto;
    margin-bottom: 15px;
    width: 150px;
    justify-content: center;
  }

  .ant-form-item-explain {
    font-size: 12px;
    margin-top: 5px;
  }

  .ant-form-item-label > label {
    @include media("<568px") {
      height: auto;
    }
  }

  .ant-input,
  .ant-input-affix-wrapper > input.ant-input,
  .ant-input-affix-wrapper{
    border-radius: 0px;
  }

  .ant {
    &-btn {
      @include commonBtnStyles;
      &-ghost {
        @include btn-border;
      }
      &-primary {
        @include btn-primary;
      }

      &-secondary {
        @include btn-secondary;
      }
    }
  }
}

.recaptcha, .auth-check {

  .ant-form-item-control-input-content {
    @include flex($justify-content: center);
  }
  .ant-form-item-explain {
    text-align: center;
  }
}
