.nav-cards {
  margin-top: 15px;
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding: 0;
    list-style: none;
  }
  &__list-item {
    width: calc(100% / 3);
    padding: 0 6px;
    padding-bottom: 12px;

    @include media("<=860px") {
      width: 50%;
    }
    @include media("<568px") {
      width: 100%;
    }
  }
}

.nav-card {
  height: 90px;
  display: flex;
  align-items: center;
  padding: 0;
  background: $darkBox;
  color: #fff;
  padding-left: 20px;
  transition: all .2s ease;

  &:hover {
    color: $secondaryColor;
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
  }
  &__image {
    margin: 0 auto;
    width: 100px;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;

    @include media('<568px') {
      margin: 0;
      margin-left: auto;
      margin-right: 15px;
    }
  }
}