.main-banner {
  z-index: 1;
  background: url("../../assets/images/banner-bg.jpg") no-repeat center / cover;

  .slick-slider {
    .slick-arrow {
      opacity: .75;
      transition: all .2s ease;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url("../../assets/images/outer/arrow-left.svg") no-repeat center center / 12px;
        opacity: 1;
      }
      &.slick-next {
        &::before {
          background-image: url("../../assets/images/outer/arrow-right.svg");
        }
      }
      &:hover {
        opacity: 1;
      }
    }
  }

}

.banner-slide {
  min-height: 375px;
  position: relative;
  color: #fff;

  @include media('<568px') {
    height: auto;
    min-height: 250px;
  }
  &__content {
    position: relative;
    z-index: 3;
    padding-left: 20px;
    padding-top: 50px;

    @include media('<1024px') {
      padding-left: 15px;
      padding-top: 15px;
    }
    @include media('<568px') {
      color: #fff;
    }
  }
  &__title {
    font-size: 42px;
    line-height: 1.3;
    font-weight: 900;
    margin-bottom: 15px;
    max-width: 500px;

    @include media('<1024px') {
      font-size: 24px;
    }
    @include media('<568px') {
      color: #fff;
    }
  }
  &__sub-title {
    max-width: 500px;
    font-size: 16px;
    line-height: 1.5;

    @include media('<1024px') {
      font-size: 14px;
    }
    @include media('<568px') {
      color: #fff;
    }
  }
  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include media('<768px') {
        position: relative;
        width: 1200px;
        left: -350px;
      }

      @include media('<568px') {
        width: 1024px;
        left: -460px;
      }
    }

    @include media('<568px') {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: .3;
        z-index: 2;
      }
    }
  }
  &__btn {
    margin-top: 20px;
    line-height: 30px;
    padding: 10px 15px;
    min-width: 150px;
    font-size: 16px;
  }
}