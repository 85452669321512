.system-table {
  overflow-x: auto;
  margin: 0 -24px;
  position: relative;
}

.inner-form-card {

  &__top {
    display: flex;
    padding-bottom: 20px;
  }

  &__controls {
    display: flex;
    padding-top: 25px;

    > * {
      + * {
        margin-left: 15px;
      }
    }
  }
}

.geo-chart-map {
  height: 700px;
  max-height: 60vh;


}

.geo-chart-map-wrap {
  margin-top: 20px;

  .ant-card-body {
    padding: 0;
  }
}

.geo-detail-modal {


  .ant-table-wrapper {
    overflow: auto;
    margin-top: 20px;
    white-space: nowrap;
  }
}

.moved-brand {
  text-align: center;
  max-width: 700px;
  border-radius: 10px;
  padding: 20px 12px;
  margin: 0 auto;
  margin-bottom: 30px;
  font-size: 16px;
  background: #fff;

  a {
    color: $primaryActiveColor;
    font-size: 18px;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__info {
    margin-top: 15px;
  }
}

.dashboard-totals {
  background: #e0e8f5;
  border: none;
  border-radius: 0px;
  overflow: hidden;

  .ant-card-body {
    padding: 0;
  }

  .ant-row {
    > .ant-col {
      max-width: initial;
      flex-grow: 1;
    }
  }


  &--gamers {
    .ant-list-item {
      height: 158px !important;
    }
  }
}

.affiliate-geo-detail-modal {
  .ant-table-wrapper {
    overflow-x: auto;
  }
}

.country-item {
  display: flex;
  align-items: center;

  img {
    width: 32px;
    margin-right: 5px;
    flex: none;
  }

  &__text {
    flex: none;
    white-space: nowrap;
  }
}

.dashboard-total-item {

  flex: none;
  margin-right: 5px;

  .cell-partner-income {
    width: 135px;
    height: 135px;
    border: 1px solid #f0f0f0;
  }
}

.cell-partner-income {
  background: $darkBox;
  padding: 15px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  flex-wrap: wrap;
  font-size: 12px;
  border-radius: 0px;

  .partner-income-details {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    list-style: none;
    padding-top: 5px;
    width: 100%;
    font-size: 10px;

    > * {
      width: 50%;
      text-align: center;
      font-size: 12px;

      + * {
        border-left: 1px solid rgba(0, 0, 0, 0.3);
      }
    }

    &__title {
      font-style: italic;
      color: rgba(250, 250, 250, 0.75);
    }

    &__value {
      font-size: 10px;

      font-weight: bold;
    }
  }

  &__total {
    text-align: center;

    + * {
      margin-top: 10px;
    }
  }

  &__total-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  &__total-value {
    font-size: 16px;
    font-weight: bold;
  }

  p {
    margin: 0;
  }

  &--details {
    background: none;
    padding: 0;
    width: 175px;
    display: block;
    height: auto;


    .cell-partner-income {
      width: auto;

      &__total {
        text-align: left;
      }

      &__total-value {
        font-size: 12px;
        text-align: left;
      }
    }


    .partner-income-details {
      justify-content: flex-start;
      padding: 0;
      line-height: 13px;

      &__title {
        font-size: 9px;
      }

      &__value {
        font-size: 10px;
        color: rgba(250, 250, 250, .7);

      }

      > * {
        + * {
          margin-left: 10px;
          padding-left: 10px;
        }

        width: auto;
        text-align: left;
      }
    }
  }

  &--sports {
    .cell-partner-income {
      &__total-value {
        font-size: 14px;
      }

      &__total-title {
        font-size: 12px;
      }
    }
  }
}

.dashboard-admin-totals {
  display: flex;
  flex-wrap: wrap;

  .cell-partner-income {
    width: 200px;
    flex: none;
    margin-right: 10px;
  }

  .dashboard-totals-item {
    text-align: center;
    padding: 5px;
    background: #fff;
    border: 1px solid #f0f0f0;
    height: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
      margin: 0;
      color: gray;
      font-size: 12px;
    }

    &__value {
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }
  }

  &__grid {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1px;

    > * {
      flex: 0 0 calc(100% / 6);
      flex-grow: 1;
    }
  }
}

.dashboard-admin-totals-item {
  background: $darkBox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;

  &__title,
  &__value {
    margin: 0;
  }

  &__value {
    font-size: 16px;
  }

  &__title {
    font-size: 10px;
    color: rgba(250, 250, 250, .4);
    font-weight: bold;
  }
}