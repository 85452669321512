.status-label {
  display: inline-block;
  vertical-align: middle;
  padding: 5px 8px;
  line-height: 10px;
  font-size: 10px;
  color: #fff;
  background-color: gray;
  border-radius: 30px;
  font-weight: 600;
  margin: 5px 0;

  &--1 {
    background-color: $successStatus;
  }
  &--2,
  &--3 {
    background-color: $radioBtnActive;

  }
  &--4 {
    background-color: $error_color;
  }
}