.filters {
  overflow: hidden;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border: none;

  &.ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }

  .ant-collapse {
    &-item {
      &.ant-collapse-no-arrow .ant-collapse-header,
      & .ant-collapse-header {
        padding: 0;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 0;
    align-items: center;
  }

  &-main {
    cursor: auto;
    padding: 22px;
    background: $white_bg;

    &-expandable {
      padding-right: 60px;
    }
  }
}
