.how-it-works {
  min-height: 500px;

  @include media('<1200px') {
    min-height: 400px;
  }
  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;

    @include media('<1024px') {
      display: block;
    }

  }

  &__info {
    margin-left: auto;
  }

  &__title {
    font-size: 64px;
    line-height: 1.2;
    color: $primaryActiveColor;
    margin-bottom: 20px;
    font-weight: 900;

    @include media('<1024px') {
      font-size: 36px;
      margin-bottom: 5px;
    }
  }

  &__image {
    width: 700px;
    position: absolute;
    left: -100px;
    top: -50px;

    @include media('<1024px') {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
      left: 0;
      position: relative;
      top: 0;
    }

    &::before {
      content: "";
      width: 450px;
      height: 400px;
      background: url("../../assets/images/outer/how_it_work_bg.png") no-repeat center / 100% 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-top: 20px;
      margin-left: -15px;

      @include media('<1024px') {
        width: 75%;
        height: 65%;
      }

    }

    img {
      max-width: 100%;
      object-fit: contain;
      position: relative;
      z-index: 2;
    }
  }

  &__buttons {
    padding: 0;
    list-style: none;
    margin: 0;

    li + li {
      margin-top: 20px;

      @include media('<1024px') {
        margin-top: 5px;
      }
    }

    button {

      display: inline-flex;
      background: $primaryActiveColor;
      color: #fff;
      padding: 10px 20px;
      line-height: 25px;
      font-size: 16px;
      font-weight: 700;
      border: none;
      cursor: pointer;
      transition: all .2s ease;
      opacity: .5;
      border-radius: 30px;

      @include media('<1024px') {
        font-size: 12px;
        line-height: 20px;
        padding: 5px 15px;
      }
      &:hover {
        background: lighten($primaryActiveColor, 5%);
      }

      &:active {
        transform: scale(.975);
      }

      &.active {
        color: #000;

        opacity: 1;
      }
    }
  }
}