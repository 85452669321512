.footer {
  left: 0;
  bottom: 0;
  padding-bottom: 20px;
  color: #fff;
  font-size: 12px;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $darkBox;
    border-radius: 30px;
    padding: 15px 20px;

    @include media('<1024px') {
      flex-direction: column;
    }

  }

  &__menu {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left: auto;


    @include media('<1024px') {
      flex-direction: column;
      margin: 15px 0;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    li {
      +li {
        margin-left: 25px;
        @include media('<1024px') {
          margin-left: 0;
          margin-top: 5px;
        }
      }
    }
    a {
      color: #fff;
      font-size: 12px;
      &:hover {
        color: $primaryColor;
      }
      &.active {
        color: $primaryColor;

      }
    }
  }
}